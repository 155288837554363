<template>
  <b-form>
    <b-form-row>
      <b-col md="6">
        <b-form-group label="Оберіть дію" label-cols="12" label-cols-lg="3">
          <multiselect
            v-model="filter.taskType.value"
            :options="taskTypes"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="false"
            placeholder=""
            label="name"
            track-by="id"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Статус ідентифікації"
          label-cols="12"
          label-cols-lg="3"
          v-if="
            filter.taskType.value &&
            (filter.taskType.value.value === 'public-persons' ||
              filter.taskType.value.value === 'sanctions')
          "
        >
          <multiselect
            :value="
              identificationStatuses.filter((e) =>
                filter.identificationStatus.value.includes(e.id)
              )
            "
            @input="filter.identificationStatus.value = $event.map((e) => e.id)"
            :options="identificationStatuses"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :show-labels="false"
            placeholder=""
            label="name"
            track-by="id"
          >
            <template class="checkbox-label" v-slot:option="scope">
              <input
                class="test"
                type="checkbox"
                :checked="
                  filter.identificationStatus.value.includes(scope.option.id)
                "
                @focus.prevent
              />
              {{ scope.option.name }}
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col md="6">
        <b-form-group
          label="Рівень ризику"
          label-cols="12"
          label-cols-lg="3"
          v-if="
            filter.taskType.value &&
            (filter.taskType.value.value === 'public-persons' ||
              filter.taskType.value.value === 'sanctions')
          "
        >
          <multiselect
            :value="
              crimeRiskLevels.filter((e) =>
                filter.currentCrimeRiskLevel.value.includes(e.id)
              )
            "
            @input="
              filter.currentCrimeRiskLevel.value = $event.map((e) => e.id)
            "
            :options="crimeRiskLevels"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :show-labels="false"
            placeholder=""
            label="name"
            track-by="id"
          >
            <template class="checkbox-label" v-slot:option="scope">
              <input
                class="test"
                type="checkbox"
                :checked="
                  filter.currentCrimeRiskLevel.value.includes(scope.option.id)
                "
                @focus.prevent
              />
              {{ scope.option.name }}
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Тип контрагента"
          label-cols="12"
          label-cols-lg="3"
          v-if="
            filter.taskType.value &&
            (filter.taskType.value.value === 'public-persons' ||
              filter.taskType.value.value === 'sanctions')
          "
        >
          <multiselect
            :value="
              counterpartTypes.filter((e) =>
                filter.counterpartType.value.includes(e.id)
              )
            "
            @input="filter.counterpartType.value = $event.map((e) => e.id)"
            :options="counterpartTypes"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :show-labels="false"
            placeholder=""
            label="name"
            track-by="id"
          >
            <template class="checkbox-label" v-slot:option="scope">
              <input
                class="test"
                type="checkbox"
                :checked="
                  filter.counterpartType.value.includes(scope.option.id)
                "
                @focus.prevent
              />
              {{ scope.option.name }}
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col md="6">
        <b-form-group
          label="Публічна особа"
          label-cols="12"
          label-cols-lg="3"
          v-if="
            filter.taskType.value &&
            filter.taskType.value.value === 'public-persons'
          "
        >
          <b-form-select
            v-model="filter.isPublic.value"
            :options="isPublicTypes"
            text-field="name"
            value-field="value"
          >
            <template #first>
              <b-form-select-option value=""></b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Профіль СПФМ"
          v-if="filter.taskType.value"
          label-cols="12"
          label-cols-lg="3"
        >
          <b-form-select
            v-model="filter.financialModel.value"
            :options="meFinancialModels"
            text-field="name"
            value-field="id"
          >
            <template #first>
              <b-form-select-option value=""></b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-button
          variant="primary"
          size="sm"
          @click="searchData"
          class="mb-2"
          v-if="filter.taskType.value"
        >
          <i class="fa fa-dot-circle-o"></i> Знайти контрагентів
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import filterList from "../structure/filter";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import {
  IDENTIFICATION_VERIFICATION_COMPLETED_VALUE,
  IDENTIFICATION_INCOMPLETE_VALUE,
  NEEDS_CLARIFICATION_INFORMATION_VALUE,
} from "@/constants";

export default {
  name: "filter-form",
  components: { Multiselect },
  data() {
    return {
      filter: filterList,
      isPublicTypes: [
        {
          name: "Так",
          value: 1,
        },
        {
          name: "Ні",
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      crimeRiskLevels: "dictionary/allCounterpartCrimeRiskLevels",
      identificationStatuses: "dictionary/allCounterpartIdentificationStatuses",
      taskTypes: "dictionary/allTaskTypes",
      counterpartTypes: "dictionary/allCounterpartTypes",
      meFinancialModels: "auth/meFinancialModels",
      dataList: "tasks/getAllCounterparts",
    }),
  },
  created() {
    this.filter.taskType.value = "";
    this.filter.counterpartType.value = [];
    this.filter.currentCrimeRiskLevel.value = [];
    this.filter.isPublic.value = "";
    this.filter.identificationStatus.value = [];
    this.filter.financialModel.value = "";
    this.identificationStatuses.forEach((value) => {
      if (
        value.value === IDENTIFICATION_VERIFICATION_COMPLETED_VALUE ||
        value.value === IDENTIFICATION_INCOMPLETE_VALUE ||
        value.value === NEEDS_CLARIFICATION_INFORMATION_VALUE
      ) {
        this.filter.identificationStatus.value.push(value.id);
      }
    });
  },
  methods: {
    searchData() {
      if (!this.filter.financialModel.value) {
        this.$snotify.error("Оберіть профіль СПФМ");
        return;
      }
      this.filter.includeIds.value = [];
      this.filter.excludeIds.value = [];
      this.$emit("clearTable");

      this.$store.commit("tasks/setCounterpartsFilter", this.filter);
      this.$store
        .dispatch("tasks/setCounterparts")
        .then(() =>
          this.dataList.length === 0
            ? this.$snotify.error("Контрагентів не знайдено")
            : null
        );
    },
  },
};
</script>
